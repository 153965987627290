const titles = {
    action: 'Action',
    title: 'Title',
    addNewTest: 'Add new test',
    idNumber: "ID number",
    dob: "Date of birth",
    enterToSearch: "Enter to search",
    edit: "Edit",
    delete: "Delete",
    show: "Show",
    birthday: "Birthday",
    gender: "Gender",
    dateCompleted: "Date Completed",
    tests: 'Tests',
    backToReports: "Back to Reports",
    report: "Report",
    close: "Close",
    showCodes: "Show Codes",
    generateReport: "Generate Report",
    logout: "Logout",
    cancel: "Cancel",
    createNewTest: "Create new test",
    numberOfQuestions: "Number of Questions",
    name: "Name",
    email: "Email",
    isVerified: "Is Verified?",
    isVisitFormLink: "Is Form Link?",
    users: "Users",
    yes: "Yes",
    no: "No",
    back: "Back",
    role: "Role",
    isLogin: "Is Login?",
    fullName: "Full Name",
    password: "Password",
    passwordConfirmation: "Password Confirmation",
    saveAndClose: "Save and Close",
    user: "User",
    addNewUser: "Add New User",
    submit: "Submit",
    backToLogin: "Back To Login",
    forgotPassword: "Forgot Your Password?",
    forgotPasswordMsg: "Enter your email address and we’ll send you a link to reset your password",
    isActive: "Is active?",
    signIn: "Sign In",
    rememberMe: "Remember Me",
    login: "Log in",
    forgotYourPassword: "Forgot your password?",
    or: "Or",
    newToInklyze: "New To Inklyze?",
    signUp: "Sign Up",
    alreadyHavingAccount: "Already Having Account?",
    pass: "Pass",
    otp: "OTP",
    next: "Next",
    verifyYourEmail: "Verify your email",
    weSentVerificationCode: "We sent a 4-digit verification code to",
    didNotGetCode: "Didn’t get a code?",
    sendAgain: "Send again",
    wrongEmailAddress: "Wrong email address?",
    choosePassword: "Choose Password",
    confirmPassword: "Confirm Password",
    savePassword: "Save Password",
    "pleaseEnterPassword": "Please enter your password and your account will register with us and you can able to login.",
    "pleaseNote": "Please note: Email is valid for 24 hours",
    resetPassword: "Reset Password",
    newPassword: "New Password",
    addUser: "Add User",
    add: "Add",
    setPassword: "Set Password",
    item: "Item",
    addSuccessfully: "Added Successfully",
    updateSuccessfully: "Updated Successfully",
    errorMessage: "Ops, something went wrong, try again",
    questionSubmittedSuccess: "Question Submitted Successfully",
    deletedSuccess: "deleted successfully",
    resetPasswordSuccessMsg: "Reset password link has been sent to your email successfully.",
    successLogin: "You have been successfully logged in",
    otpSuccessMsg: "OTP send to your mail successfully",
    alreadyRegistered: "Already Registered!",
    otpVerifiedMsg: "OTP Verified Successfully !",
    otpSentSuccessMsg: "OTP Sent Successfully !",
    registerSuccessMsg: "Register Successfully !",
    resetPasswordLinkExpiredMsg: "Reset password link is expired,try again",
    passwordResetSuccessMsg: "Password Reset Successfully !",
    subscription: "Subscription",
    noActiveSubscription: "No active subscription",
    purchase: "Purchase",
    plan: "Plan",
    planDescription: "monthly subscription with access to attempts maximum 10 questions",
    buy: "Buy",
    holdersName: "Holders Name",
    creditCardNumber: "Credit Card Number",
    expiration: "Expiration",
    save: "Save",
    country: "Country",
    subscriptionSuccessMsg: "Subscription Successfully added",
    recordNotFound: "Record Not Found",
    card: "Card",
    cardDetailSuccessMsg: "Card Detail Successfully updated",
    questionAddedSuccessMsg: "Question Added Successfully",
    questionDeletedSuccessMsg: "Question Deleted Successfully",
    totalCredit: "Total Credit",
    creditHistory: "Credit History",
    subscriptionCredit: "Subscription Credit",
    freeCredit: "Free Credit",
    usedCredit: "Used Credit",
    pendingCredit: "Pending Credit",
    note: "Note",
    credit: "Credit",
    test: "Test",
    type: "Type",
    testCredit: "Test Credit",
    amount: "Amount",
    status: "Status",
    startDate: "Start Date",
    endDate: "End Date",
    newSubscription: "New Subscription",
    testFreeCredit: "Test Free Credit",
    expiryDate: "Expiry Date",
    subscribed: "Subscribed",
    unsubscribed: "Unsubscribed",
    profile: "Profile",
    account: "Account",
    wallet: "Wallet",
    exportToPDF: "Export to PDF",
    reportProcessMessage: "Your Report preparation is under process. You will get a mail when Report is ready.",
    update: "Update",
    emailChangeOTPSuccessMsg: "OTP Sent Successfully !",
    emailChangedSuccessMsg: "Email updated successfully, Please login again!"
};

export default {
    locale: "en-US",
    messages: {
        title: titles
    },
};
