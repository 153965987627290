<template>
    <div class="mt-5">
        <b-row>
            <b-col sm="8" xs="12">
                <form @submit.prevent="sendVerificationOTP" autocomplete="off">
                    <b-card>
                        <b-card-body>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <label for="email" class="fw-medium mb-2 text-grey-input">{{
                                            $t('title.email')
                                        }}</label>
                                    <div class="d-flex">
                                        <input
                                            v-model="formFields.email"
                                            type="text"
                                            class="form-control form-input mr-20"
                                            id="email"
                                            name="email"
                                            @focus="removeError('email')"
                                            :class="formErrors.first('email') ? `is-invalid form-floating` : `form-floating`"
                                        />
                                        <button type="submit" class="btn btn-warning d-block fs-20 w-50" :disabled="isSubmitEmail">
                                            <i class="fa fa-spinner fa-spin" v-if="isSubmitEmail"></i>
                                            {{ $t('title.update') }}
                                        </button>
                                    </div>

                                    <p class="text-danger" v-if="formErrors.has('email')">
                                        {{ formErrors.first('email') }}
                                    </p>
                                </div>
                            </div>
                        </b-card-body>
                    </b-card>
                </form>
            </b-col>
        </b-row>
        <change-email-verify-o-t-p-modal ref="changeEmailVerifyModal" :email="formFields.email"/>
    </div>
</template>

<script>
import Error from '@/Util/Error';
import { request } from '@/Util/Request';
import {mapGetters} from "vuex";
import changeEmailVerifyOTPModal from "@/views/profile/partials/modal/changeEmailVerifyOTPModal";

const DEFAULT_FORM_STATE = {
    email: null,
};

export default {
    name: 'ChagneEmail',
    data() {
        return {
            formFields: { ...DEFAULT_FORM_STATE },
            loading: false,
            formErrors: new Error({}),
            showModal: false,
            modal: null,
            isValidateEmail: false,
            isSubmitEmail: false
        };
    },
    components: {
        changeEmailVerifyOTPModal
    },
    mounted() {
        this.formFields.email  = this.user.email
    },
    methods: {
        validateEmail() {
            /* eslint-disable no-useless-escape */
            if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.formFields.email) && this.user.email != this.formFields.email) {
                this.isValidateEmail = true;
                if(this.formErrors.$errors.email && this.isValidateEmail) {
                    delete this.formErrors.$errors.email;
                }
            } else {
                this.isValidateEmail = false;
            }
        },
        async sendVerificationOTP() {
            try {
                this.isSubmitEmail = true;
                let payload = this.formFields;
                const response = await request({
                    method: 'POST',
                    url: `/profile/change-email`,
                    data: payload,
                });
                const { data } = response;
                if ( data ) {
                    this.notifySuccessWithMsg(this.$t('title.emailChangeOTPSuccessMsg'));
                    this.$refs['changeEmailVerifyModal'].handleToggleModal();
                }
            } catch (error) {
                this.isValidateEmail = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                }

                if ( error.request && error.request.status && error.request.status === 429 ) {
                    this.formErrors = new Error({
                        email: 'unauthentic'
                    });
                }
            }
            this.isSubmitEmail = false;
        },
        async removeError(key) {
            this.formErrors.remove(key);
        }
    },
    computed: {
        ...mapGetters(['user']),
    }
};
</script>
