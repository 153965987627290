<template>
    <header class="bg-white main-header">
        <div class="container">
            <div class="row">
                <div class="col-auto d-flex align-items-center">
                    <router-link v-if="user?.role == 'user'" :to="{name:'test'}"><img src="../assets/logo.svg"
                                                                                      alt="logo" width="89" />
                    </router-link>
                    <router-link v-else to="/"><img src="../assets/logo.svg" alt="logo" width="89" /></router-link>
                </div>
                <div class="ms-auto col-auto text-end" v-if="user" id="profile-icon-header">
                    <span class="me-2">

                         <b-dropdown id="dropdown-right" right :text="$t('title.account')"
                                     class="m-2 account">
                            <b-dropdown-item :class="{'active': setActiveTab('subscription')}" :to="{name:'subscription'}">
                                {{$t('title.subscription') }}
                            </b-dropdown-item>
                            <b-dropdown-item :class="{'active': setActiveTab('wallet')}" :to="{name:'wallet'}">
                                {{$t('title.wallet') }}
                            </b-dropdown-item>
                         </b-dropdown>

                        <router-link :to="{name:'profile'}" v-if="user?.role !== 'admin'">
                            <i class="bi bi-person"></i> Profile
                        </router-link>
                        <router-link v-else :to="{name:'adminProfile'}">
                            <i class="bi bi-person"></i> Profile
                        </router-link>
                        <span>
                            {{username}}
                        </span>
                    </span>
                    <span @click="openCloseLogoutModal" role="button">
                        <strong><i class="bi bi-power"></i></strong>
                    </span>
                </div>
            </div>
        </div>
        <logout-modal ref="logoutModal" />
    </header>
</template>

<script>
import { mapGetters } from "vuex";
import LogoutModal from "@/views/auth/modal/logoutModal";
import {request} from "@/Util/Request";

export default {
    name: 'AppHeader',
    components: { LogoutModal },
    data() {
        return {
            activeTab : '',
            username:''
        };
    },
    mounted() {
        this.checkTest();
    },
    methods: {
        async checkTest() {
            try {
                const response = await request({
                    method: 'get',
                    url: '/check-test',
                });

                const { data } = response;
                this.username = data.username;
                console.log("get test api",data.username);

            } catch (error) {
                console.log(error);
            }
        },
        async openCloseLogoutModal() {
            this.$refs.logoutModal.handleToggleModal();
        },
        setActiveTab(tab) {
            return this.$route.name == tab;
        }
    },
    computed: {
        ...mapGetters(['user'])
    }
};
</script>
