<template>
    <div>
        <b-modal v-model="isOpenModal" id="deleteCardQuestionModal"  :hide-header="true"
                 :hide-footer="true">
            <p>Are you sure you want to delete question? </p>
            <div class="modal-footer d-flex mr-2 gap-2">
                <button type="button" class="btn main-btn col m-0" @click="handleSubmitDeleteQuestion">{{ $t('title.yes') }}</button>
                <button type="button" class="btn sec-btn col m-0" @click="handleToggleModal">
                    {{ $t('title.cancel') }}
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {request} from "@/Util/Request";
import Error from "@/Util/Error";


export default {
    data() {
        return {
            isOpenModal: false,
            questionId: null,
            formErrors: new Error({}),
        };
    },
    methods: {
        handleToggleModal(questionId) {
            this.questionId = questionId;
            this.isOpenModal = !this.isOpenModal;
        },
        async handleSubmitDeleteQuestion() {
            try {
                const response = await request({
                    method: 'delete',
                    url: `/test-questions/${this.questionId}`
                });

                if(response) {
                    this.notifySuccessWithMsg(this.$t('title.questionDeletedSuccessMsg'));
                    this.handleToggleModal();
                }
                this.$emit('refresh');
            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
            }
        },
    }
};
</script>
